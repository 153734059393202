<template>
  <MoviesSlider />
</template>

<script>
import MoviesSlider from '@/components/MoviesSlider.vue'

export default {
  name: 'Home',
  components: { MoviesSlider }
}
</script>
