<template>
  <router-view />
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&family=Lato:ital,wght@0,300;0,400;0,700;1,400&family=Open+Sans:wght@300;400;500;600;700&display=swap');

body {
  margin: 0 !important;
  padding: 0 !important;
}
</style>